// Modal css\

.custom-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.65);
  z-index: 99;
  &.dark {
    background-color: rgba(0, 0, 0, 0.9);
  }
}
.test-class {
  outline: none; // This is the fix so that it never happens again (the white border weird)
}
.scrollable-modal {
  height: 100vh;
  overflow-y: auto;
}
.custom-modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: none;
  background: #fff;
  overflow: initial;
  border-radius: 10px;
  outline: none;
  .box_inner {
    height: auto;
    width: 600px;
    overflow-y: auto;
    background: linear-gradient(
    120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    animation: gradientColor 5s ease-in-out infinite;
    padding: 5px;

    @media (max-width: 1400px) {
      width: 630px;
    }
    @media (max-width: 992px) {
      width: 600px;
    }
    // @media (max-width: 768px) {
    //   width: 560px;
    // }
    @media (max-width: 599px) {
      width: 460px;
    }
    @media (max-width: 490px) {
      width: 360px;
    }
    @media (max-width: 420px) {
      width: 320px;
    }
    &.blog-post {
      padding: 0 50px;
      @media (max-width: 767px) {
        padding: 0 15px;
      }
      .title-section {
        padding: 50px 0;
        @media (max-width: 767px) {
          padding: 50px 0 20px;
        }
      }
    }
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all 0.3s ease;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.close-modal {
  background: transparent;
  border: none;
  display: flex;
  width: 45px;
  height: 45px;
  position: absolute;
  z-index: 4;
  right: 0px;
  top: 5px;
  @media screen and (max-width: 1199px) {
    right: 16px;
    top: 10px;
  }
  @media screen and (max-width: 575px) {
    right: -5px;
    top: 4px;
  }
  img {
    width: 25px;
    height: 25px;
    cursor: pointer;
    position: absolute;
    filter: invert(1);
    transition: 0.3s;
    @media screen and (max-width: 575px) {
      width: 30px;
      height: 30px;
    }
  }
  &:hover {
    img {
      filter: invert(0.8);
    }
  }
}

.custom-modal.dark .box_inner::-webkit-scrollbar {
  width: 8px; /* width of the entire scrollbar */
}

.custom-modal.dark .box_inner::-webkit-scrollbar-track {
  background: #333; /* color of the tracking area */
}

.custom-modal.dark .box_inner::-webkit-scrollbar-thumb {
  background-color: #555; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid #333; /* creates padding around scroll thumb */
}
body.light .custom-modal.dark .box_inner::-webkit-scrollbar-track {
  background: #fff; /* color of the tracking area */
}

body.light .custom-modal.dark .box_inner::-webkit-scrollbar-thumb {
  background-color: #bbb; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 1px solid #fff; /* creates padding around scroll thumb */
}
.custom-modal.dark.light .box_inner.about {
  border: 2px solid #fff;
}
.custom-modal.dark {
  background: #252525;
  &.hero {
    background: #111;
  }
  &.light {
    background: #fff;
  }
  .box_inner {
    &.about {
      border-radius: 5px;
      @media (min-width: 1600px) {
        width: 1190px;
      }
      @media (max-width: 1600px) {
        width: 1070px;
      }
      @media (max-width: 1400px) {
        width: 900px;
      }
      @media (max-width: 992px) {
        width: 700px;
      }
      @media (max-width: 599px) {
        width: 460px;
      }
      @media (max-width: 490px) {
        width: 360px;
      }
      @media (max-width: 420px) {
        width: 320px;
      }
      border: 2px solid #222;
    }
    &.portfolio {
      @media (min-width: 1600px) {
        width: 660px;
        height: auto;
      }
      @media (max-width: 1499px) {
        height: auto;
      }
    }
  }
}
