#switcher {
  background: #fff;
  position: fixed;
  top: 100px;
  left: 0;
  direction: ltr;
  z-index: 99;
  width: 220px;
  margin-left: 0;
  border-radius: 0 5px 5px 0;
  font-family: "Open Sans", Arial, sans-serif;
  -webkit-box-shadow: 0px 0px 4px 0px rgba(51, 51, 51, 0.2);
  box-shadow: 0px 0px 4px 0px rgba(51, 51, 51, 0.2);
  transform: translateX(-100%);
  transition: 0.5s;

  &.switcher-open {
    transform: translateX(0);
  }
}

#switcher label {
  padding-right: 20px;
  padding-left: 20px;
  font-weight: normal;
  cursor: pointer;
  color: #333;
  position: relative;
}
#switcher hr {
  border: 0;
  border-bottom: #ccc 1px solid;
  margin: 10px 0;
}
#switcher h4 {
  padding: 5px 0;
  border-bottom: #ccc 1px solid;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: bold;
  color: #333;
  margin: 0 0 20px 0;
}

#switcher ul {
  padding: 0 !important;
  margin: 20px 0 !important;
}
#switcher ul li {
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 12px;
  display: inline-block;
}
#switcher ul li a img {
  width: 30px;
}

#showSwitcher {
  z-index: 94;
  margin-left: 0px;
  position: fixed;
  left: 0;
  top: 210px;
  background: #fff;
  box-shadow: #ccc 0 0 2px;
  border-left: 0;
  font-size: 18px;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-topright: 4px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 4px;
  padding: 10px 16px 10px 14px;
  cursor: pointer;
  transform: translateX(0);
  transition: 0.5s;
  transition-delay: 0.5s;

  &.active {
    transform: translateX(-100%);
  }
}
#hideSwitcher {
  cursor: pointer;
  line-height: 43px;
  font-size: 38px;
  margin-bottom: 0px;
  color: #999;
  position: absolute;
  background-color: #fff;
  width: 40px;
  height: 40px;
  top: 7px;
  right: 16px;
  text-align: right;
  -webkit-border-bottom-right-radius: 3px;
  -webkit-border-top-right-radius: 4px;
  -moz-border-radius-bottomright: 3px;
  -moz-border-radius-topright: 4px;
  border-bottom-right-radius: 3px;
  border-top-right-radius: 4px;
}

#switcher .content-switcher {
  padding: 16px;
  overflow: hidden;
}

#switcher a.color {
  border-radius: 2px;
  cursor: pointer;
  display: inline-block;
  margin: 4px 2px;
  width: 30px;
  height: 30px;
}

#switcher p small {
  font-size: 10px;
}
#switcher p {
  margin: 15px 0 10px 0;
  font-size: 12px;
  font-weight: 400;
  color: #2e363f;
}
#showSwitcher i {
  font-size: 25px;
  color: #222;
}
#switcher .styled-select select {
  background: #f5f5f5;
  width: 100%;
  font-size: 13px;
  line-height: 13px;
  outline: none;

  -webkit-appearance: none;
  cursor: pointer;
}
#switcher p.separator {
  margin-bottom: 0;
}
#switcher span.hot {
  background: #ff0000;
  color: #fff;
  padding: 4px;
  border-radius: 6px;
  margin-left: 4px;
  font-weight: 700;
}
#switcher span.info {
  font-size: 11px;
  display: block;
  color: #118ce7;
  font-style: italic;
}

/** ============ **/
#switcher .switcher_thumb {
  display: inline-block;
  margin: 4px 3px;
}
#switcher .switcher_thumb.selected {
  box-shadow: #666 0 0 5px;
}
#switcher .purchase {
  background-color: #82b440;
  text-transform: capitalize;
  font-size: 14px;
  border-radius: 4px;
  font-weight: 500;
  display: block;
  text-align: center;
  margin-top: 25px;
}
@media only screen and (max-width: 991px) {
  #switcher {
    top: 40px;
  }
  #showSwitcher {
    top: 15px;
    display: none;
  }
}

#switcher .dark_switch {
  margin-top: 5px;
  opacity: 1;
  left: 0;
}
#switcher .purchase {
  text-transform: uppercase;
  transform: none !important;
  color: #fff;
  padding: 12px 26px;
  border-radius: 30px;
  font-weight: 600;
  text-decoration: none;
}
#switcher .purchase i {
  padding-right: 10px;
}

.switcher-wrapper {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -4px;
  button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    display: inline-flex;
    margin: 4px;
  }
}

.switcher-wrapper {
  .blue {
    background: #4169e1;
  }
  .blueviolet {
    background: #8a2be2;
  }
  .goldenrod {
    background: #daa520;
  }
  .green {
    background: #72b626;
  }
  .magenta {
    background: #ee6192;
  }
  .orange {
    background: #fa5b0f;
  }
  .purple {
    background: #6957af;
  }
  .red {
    background: #f72b1c;
  }
  .yellow {
    background: #ffb400;
  }
  .yellowgreen {
    background: #9acd32;
  }
  button:focus {
    outline: 1px dotted !important;
    outline: 5px auto -webkit-focus-ring-color !important;
  }
}
