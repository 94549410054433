@font-face {
  font-family: 'Game Of Squids';
  src: local('Game Of Squids'), url(./GameOfSquids-1GMVL.ttf) format('truetype');
}

@font-face {
  font-family: 'Hemi Head';
  src: local('Hemi Head'), url(./hemi_head_bd_it.ttf) format('truetype');
}

@mixin glitchCopy { 
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.glitch {
  position: relative;
  color: #FFF;
  font-family: 'Hemi Head';
  font-size: 80px !important;
  font-weight: 900;
  animation: glitch-skew 1s infinite linear alternate-reverse;
  line-height: 75px !important;
  &::before{
      @include glitchCopy;
      left: 2px;
      text-shadow: -2px 0 #ff00c1;
      clip: rect(44px, 450px, 56px, 0);
      animation: glitch-anim 5s infinite linear alternate-reverse;
  }

  &::after {
      @include glitchCopy;
      left: -2px;
      text-shadow: -2px 0 #00fff9, 2px 2px #ff00c1;
      animation: glitch-anim2 1s infinite linear alternate-reverse;
  }
}

@keyframes glitch-anim {
$steps: 20;
@for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-anim2 {
  $steps: 20;
@for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
            transform: skew((random(100) / 100) + deg);
    }
  }
}

@keyframes glitch-skew {
  $steps: 10;
  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      transform: skew((random(10) - 5) + deg);
    }
  }
}

@media (max-width:767px)
{ 
  .glitch {
    font-size: 45px !important;
    line-height: 50px !important;
    &::before{
      @include glitchCopy;
      left: 1px !important;
      text-shadow:-1px 0 #ff00c1 !important;
  }

  &::after {
      @include glitchCopy;
      text-shadow: -1px 0 #00fff9, 1px 1px #ff00c1 !important;
      left: -1px !important;
  }
  }
}