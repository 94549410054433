.home .color-block{
    background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
    background-size: 300% 300%;
    animation: gradientColor 5s ease-in-out infinite;
    z-index: -2;
}

@keyframes gradientColor {
0% {
    background-position: 15% 0%;
}
50% {
    background-position: 85% 100%;
}
100% {
    background-position: 15% 0%;
}
}

.bg {
    background: linear-gradient(120deg, #cc0045, #00b8bc);
    background-size: 300% 300% !important;
    animation: gradientColor 5s ease-in-out infinite;
}
.main-content.video-landscape {
    max-height: 100vh;
}



@media (max-width: 1095px) {
.video-landscape {
    display:flex;
    place-content:center;
    video {
        width:100vh;
        height:100vh;
        transform:rotate(90deg);
    }
}
}
@media only screen and (max-width:1548px) and (min-width:1096px) {
    .video-landscape {
        display:flex;
        place-content:center;
        video {
            height:100vh;
        }
    }
    }

@media (min-width:1548px) {
    .video-landscape video {
        width: 100%;
    }
}


@media (max-width:991px) {
    .padding-ab {
        padding-bottom: 60px !important;
    }
}

// .video-landscape {
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
//     overflow: hidden;
//     video {
//         min-width: 100%;
//         min-height: 100%;
//         object-fit: cover;
//         position: absolute;
//         top: 50%;
//         left: 50%;
//         transform: translate(-50%,-50%);
//     }
// }


::-webkit-scrollbar {
    display: none;
  }

  .img-mobile {
      width:100%;
      height:100%;
      background-image: url('img/img-mobile.png');
      display:block;
      background-size: cover;
      background-position: 49.5%;
      transform: scale(1.31);
  }

.demo-sticker {
    position:fixed;
    top:20px;
    right:30px;
    z-index: 99;
    text-align: center;

    img {
        height:100px;
    }
}

@media (max-width: 576px) {
    .demo-sticker {
        top:0;
        text-align: center;
        width:100%;
        right: 0;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 5px 0;
        img {
        height:55px;
    }
}
}

.portfolio-img-gradient {
    background-image: linear-gradient(
315deg, #ffffff 0%, #d7e1ec 74%);
    background-size: 300% 300%;
    animation: gradientColor 5s ease-in-out infinite;
}


  //mobile responsive
@media (max-width: 576px)
{
    .title-section
    {
        padding: 60px 0 10px 0;
    }
}

@media (max-width: 576px) {
    .portfolio-tab-list li {
        margin: 0 10px;
        margin-top: 20px;
        font-size:15px;
    }
    .portfolio-tab-list {
        margin-top: 10px !important;
    }
    .display-none-mb {
        display:none;
    }
}

@media (max-width: 576px) {
    .mds-c-example {
        position:fixed;
        background:transparent !important;
        transform:rotate(90deg);
        width: 100%;
        bottom:71%;
        left:45%;
        margin:-25px 0 -25px -4px !important;
        place-content:start !important;
        }
        .mds-c-icon {
            height:1.3em;
            width:1.5em;
            margin-left:-15px;
            transform: rotate(266deg);
            bottom:4px;
        }
    
}


//bell contact//
@keyframes mds-i-bell--ring {
    10% { transform: rotate(15deg); }
    18% { transform: rotate(-30deg); }
    30% { transform: rotate(30deg); }
    40% { transform: rotate(0); }
  }
  
  @keyframes mds-i-bell--clap {
    10% { transform: translateX(15%); }
    18% { transform: translateX(-20%); }
    25% { transform: translateX(20%); }
    35% { transform: translateX(-20%); }
    43% { transform: translateX(0); }
  }
  
  @keyframes mds-i-bell--rip {
    0%,
    10% {
      opacity: 1;
      transform: scale(0, 0);
    }
    50%,
    100% {
      opacity: 0;
      transform: scale(4, 4);
    }
  
  }
  
  .mds-i-bell--animated {
    .mds-i-bell__bell {
      animation: mds-i-bell--ring 3s infinite ease-in-out 1s;
      transform-origin: center top; 
    }
    
    .mds-i-bell__clapper {
      animation: mds-i-bell--clap 3s infinite ease-in-out 1s;
    }
    
    .mds-c-icon__badge-dot {
      fill: rgb(245, 60, 32);
      stroke-width: 2;
      stroke: #ccc;
    }
    
    .mds-c-icon__badge-ripple {
      opacity: 0;
      fill: none;
      stroke-width: 2;
      stroke: rgb(245, 60, 32);
      transform-origin: calc(50% * 1.375) calc(50% * 0.375);
      animation: mds-i-bell--rip 3s infinite 1s;
    }
  }

  .mds-c-icon {
    display: inline-block;
    fill: currentColor;
    flex-shrink: 0;
    height: 1em;
    line-height: inherit;
    overflow: visible;
    position: relative;
    vertical-align: -0.2em;
    width: 1.6em;
    font-size: 2.5em;
    margin-top:5px;
  }

  .mds-c-example {
      display:flex;
      place-content: center;
      margin: 3px -5px;
      cursor: pointer;    
      padding: 0 10px;
      margin-left: -41px;
      #contactText {
          margin: auto 0;
      }
  }


//proposal stroke
.space {
     padding-right: 5px;
}
#contactText span{
    animation: animate 2s linear infinite;
}
#contactText {
    font-size:16px;
}

@keyframes animate
{
    0%,100%
    {
      color: #00ffff;
      text-shadow: 0 0 4px #00ffff,
                  0 0 8px #00ffff,
                  0 0 16px #00ffff,
                  0 0 32px #00ffff,
                  0 0 64px #00ffff,
                  0 0 128px #00ffff,
                  0 0 150px #00ffff,
                  0 0 200px #00ffff;
    }
    5%,95%
    {
      color: #fff;
      text-shadow: none;
    }
}
#contactText span:nth-child(1)
{
  animation-delay: 0s;
}
#contactText span:nth-child(2)
{
  animation-delay: 0.25s;
}
#contactText span:nth-child(3)
{
  animation-delay: 0.5s;
}
#contactText span:nth-child(4)
{
  animation-delay: 0.75s;
}
#contactText span:nth-child(5)
{
  animation-delay: 1s;
}
#contactText span:nth-child(6)
{
  animation-delay: 1.25s;
}
#contactText span:nth-child(7)
{
  animation-delay: 1.5s;
}
#contactText span:nth-child(8)
{
  animation-delay: 1.75s;
}
#contactText span:nth-child(9)
{
  animation-delay: 2s;
}
#contactText span:nth-child(10)
{
  animation-delay: 2.25s;
}
#contactText span:nth-child(11)
{
  animation-delay: 2.5s;
}
#contactText span:nth-child(12)
{
  animation-delay: 2.75s;
}
#contactText span:nth-child(13)
{
  animation-delay: 3s;
}
#contactText span:nth-child(14)
{
  animation-delay: 3.25s;
}
#contactText span:nth-child(15)
{
  animation-delay: 3.5s;
}
#contactText span:nth-child(16)
{
  animation-delay: 3.75s;
}
//bell contact//



//blog redesign //
.new-blog {
    height:75vh !important;
    padding:2px !important;
}
.new-blog article {
    background: #121212 !important;
    padding: 25px 25px;
}
.new-blog .title-section {
    padding: 20px 0 !important;
}
.blog-excerpt strong {
  font-size: 1.5em;
  color:white;
}
.blog-excerpt 
p {
  color: #444;
}
.blog-excerpt li {
  color: #444;
}
.blog-excerpt li strong{
  font-size: 1em;
}
.blog-excerpt li::marker {
  color: #00b8bc;
}
.blog-excerpt
a {
  color:#04e5e5;
}
//blog redesign //

//contact redesign//

@media screen and (max-width:575px) {
  .ContactContainer {
    max-width: 341px !important;
    margin: 18px auto 18px !important;
    padding: 15px 15px !important;
  }
}

.signUpNewsletter {
  margin-top: -20px !important;
}


.ablogo-mobile {
  display:none;
}


@media screen and (max-width:576px) {
  .ablogo-mobile {
    display: inline;
  }
}

@media screen and (max-width:991px) {
  .ablogo-desktop {
    display:none;
  }
}

.tab-panel_list > div.home {
  background-image: url('img/motoGP.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  /* Adjust background position for mobile */
  background-position: right; /* Adjust this value as needed */
}

@media (max-width: 576px) {
  .tab-panel_list > div.home {
      background-position: 85% 50%; /* Adjust this value as needed */
  }
}


